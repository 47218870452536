import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import {
  FaBalanceScale,
  FaBriefcaseMedical,
  FaChartPie,
  FaCity,
  FaDove,
  FaGraduationCap,
  FaHandHoldingUsd,
  FaLandmark,
  FaLeaf,
  FaPeopleCarry,
} from 'react-icons/fa';
import Button from '../components/button';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import Section from '../components/section';
import SEO from '../components/seo';
import PageNavigator from '../components/pageNavigator';
import Panel from '../components/panel';
import SafeLink from '../components/safeLink';

const ProjectsMPlan = ({ data, location }) => {
  const parentTitle = "The Centre for Social Justice's";
  const pageTitle = 'Second Annual Social Justice Summit';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:30vh;`]}
      />
      <PageNavigator
        items={{
          welcome: 'Welcome',
          // 'm-plan': 'The Social Justice M-Plan',
          programme: 'Programme',
          speakers: 'Speakers',
          partner: 'Partner with us',
          watch: 'Watch now',
          press: 'Press coverage',
        }}
      />
      <Section id="welcome">
        <h2>Welcome!</h2>
        <Img
          fluid={data.summit2020Banner.childImageSharp.fluid}
          alt="The Second Annual Social Justice Summit, 4 November 2020"
          css={tw`w-full`}
        />
        <hr css={tw`mt-3 mb-10`} />
        <Grid gridCols={1} mdGridCols={2} lgGridCols={2}>
          <div>
            <h3>
              All hands on deck to leave no one behind in the post COVID-19
              recovery agenda
            </h3>
            <LeadText>
              The aim of this year's summit is to launch the{' '}
              <SafeLink
                to="/downloads/m-plan/m-plan-structures.pdf"
                css={tw`border-b-2 border-maroon hover:text-maroon`}
              >
                M-Fund
              </SafeLink>{' '}
              which is a crowdfunding initiative to accelerate the advancement
              of equality and the elimination of poverty in pursuit of SDG 10.
              Our one-day programme continues on last year's Summit and our
              speakers look forward to engaging with you online. We invite you
              to join us as we deepen our understanding of and commitment to
              social justice.
            </LeadText>
            <LeadText css={tw`text-gray-600`}>
              The Second Annual Social Justice Summit
              <br />4 November 2020
            </LeadText>
            <Button to="#watch" size="md" css={tw`mb-2`}>
              Watch now &rarr;
            </Button> <Button to="/downloads/summit-2020/summary-report-summit-2020.pdf" size="md" css={tw`mb-2`}>
              Download summary report &rarr;
            </Button> <Button to="/downloads/summit-2020/full-report-summit-2020.pdf" size="md" css={tw`mb-2`}>
              Download full report &rarr;
            </Button>

          </div>
          <div css={tw`mt-10 md:mt-0`}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/37HvlV2cxmA?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              css={tw`max-w-full`}
            />
          </div>
        </Grid>
      </Section>
      <Section id="programme" isShaded>
        <h2>Programme</h2>
        <LeadText>
          SDG 10 aims to reduce poverty and eradicate inequality. Under the
          theme “All hands on deck to leave no one behind in the post COVID-19
          recovery agenda” our speakers and panellists will unpack the topics
          below.
        </LeadText>
        <Button
          to="/downloads/summit-2020/summit-2020-programme.pdf"
          size="md"
          css={tw`mb-10`}
        >
          Download the programme &rarr;
        </Button>
        <Grid>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaHandHoldingUsd
              css={[
                tw`mr-4 text-maroon text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Land and poverty</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaCity
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Economic inclusion and well-being</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaGraduationCap
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Education and training</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaChartPie
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Data analytics and poverty mapping</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaBriefcaseMedical
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Health, mental health, and nutrition</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaBalanceScale
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Access to justice and the rule of law</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaDove
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Peace and social cohesion</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaLeaf
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>
              Climate change and environmental sustainability
            </h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaLandmark
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Politics and democracy</h3>
          </Panel>
          <Panel size="sm" css={tw`flex items-center`}>
            <FaPeopleCarry
              css={[
                tw`mr-4 text-maroon  text-4xl align-text-bottom`,
                `flex-shrink:0;`,
              ]}
            />
            <h3 css={tw`mb-0`}>Gender and other excluded groups</h3>
          </Panel>
        </Grid>
      </Section>
      <Section id="speakers">
        <h2>Keynote Speakers</h2>
        <LeadText>
          We have an exciting array of speakers, including the ones below — and
          many more. Now that the summit is past, you can read speakers'
          speeches below.
        </LeadText>
        {/* <Button to="#" size="md" css={tw`mb-10`}>
          View the full list of speakers, with bios &rarr;
            </Button> */}
        <Grid>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.atBoshoff.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Pastor At Boshoff</h3>
            <LeadText css={tw`mb-0`}>
              Founder, Christian Revival Church (CRC)
            </LeadText>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.wimDeVilliers.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Wim de Villiers</h3>
            <LeadText css={tw`mb-0`}>
              Rector and Vice-Chancellor, Stellenbosch University
            </LeadText>
            <p>
              <SafeLink
                to="/downloads/summit-2020/prof-wim-de-villiers-social-justice-summit.pdf"
                css={tw`border-b-2 border-maroon hover:text-maroon`}
              >
                Read speech
              </SafeLink>
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.adrianEnthoven.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Dr. Adrian Enthoven</h3>
            <LeadText css={tw`mb-0`}>
              Executive Chairman, Yellowwoods Investment Group
            </LeadText>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.ronaldLamola.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Minister Ronald Lamola</h3>
            <LeadText css={tw`mb-0`}>
              Minister for Justice and Correctional Services, Republic of South
              Africa
            </LeadText>
            <p>
              <SafeLink
                to="/downloads/summit-2020/minister-lamola.pdf"
                css={tw`border-b-2 border-maroon hover:text-maroon`}
              >
                Read speech
              </SafeLink>
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.thaboMakgoba.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Archbishop Thabo Makgoba</h3>
            <LeadText css={tw`mb-0`}>Archbishop of Cape Town</LeadText>
            <p>
              <SafeLink
                to="/downloads/summit-2020/archbishop-makgoba-social-justice-summit-talking-points-for-archbishop-makgoba.pdf"
                css={tw`border-b-2 border-maroon hover:text-maroon`}
              >
                Read speech
              </SafeLink>
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.busisiweMavuso.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Busisiwe Mavuso</h3>
            <LeadText css={tw`mb-0`}>
              Chief Executive Officer, Business Leadership SA
            </LeadText>
            <p>
              <SafeLink
                to="/downloads/summit-2020/busisiwe-mavuso-the-role-of-business-in-intergrating-the-post-covid-recovery-program-with-the-sdg-quest.pdf"
                css={tw`border-b-2 border-maroon hover:text-maroon`}
              >
                Read speech
              </SafeLink>
            </p>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.kateRobertson.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Kate Robertson</h3>
            <LeadText css={tw`mb-0`}>Co-founder, One Young World</LeadText>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.gloriaSerobe.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Gloria Serobe</h3>
            <LeadText css={tw`mb-0`}>
              Chairperson of the Solidarity Fund and Founder of the Women
              Investment Portfolio Holdings Limited (WIPHOLD){' '}
            </LeadText>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.clareShine.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Clare Shine</h3>
            <LeadText css={tw`mb-0`}>
              Vice President and Chief Program Officer, Salzburg Global Seminar
            </LeadText>
          </div>
          <div css={tw`p-5 shadow-lg`}>
            <Img
              fixed={data.nicolaSmit.childImageSharp.fixed}
              css={tw`mb-3 rounded-full`}
            />
            <h3 css={tw`mb-0`}>Prof. Nicola Smit</h3>
            <LeadText css={tw`mb-0`}>
              Professor of Law and Dean, Faculty of Law, Stellenbosch University
            </LeadText>
            <p>
              <SafeLink
                to="/downloads/summit-2020/prof-n-smit-concluding-remarks.pdf"
                css={tw`border-b-2 border-maroon hover:text-maroon`}
              >
                Read speech
              </SafeLink>
            </p>
          </div>
        </Grid>
      </Section>
      <Section id="partner" isShaded>
        <h2>Partner with us</h2>
        <LeadText>
          The M-Fund is a crowdfunding initiative to accelerate the advancement
          of equality and the elimination of poverty in pursuit of SDG 10.
          Explore our opportunities for partnership to support the M-Fund and
          the Second Annual Social Justice Summit.
        </LeadText>
        <table css={[tw`w-full bg-white`, `border-collapse:collapse;`]}>
          <tr>
            <td css={tw`p-5 border border-gray-400`}>
              <h3 css={tw`mb-2`}>PREMIUM SPONSOR</h3>
              <LeadText>R30 000+</LeadText>
            </td>
            <td css={tw`p-5 border border-gray-400`}>
              <h3 css={tw`mb-2`}>PLATINUM SPONSOR</h3>
              <LeadText>R20 000 - R29 000</LeadText>
            </td>
            <td css={tw`p-5 border border-gray-400`}>
              <h3 css={tw`mb-2`}>GOLD SPONSOR</h3>
              <LeadText>R10 000 - R19 000</LeadText>
            </td>
          </tr>
          <tr>
            <td css={tw`p-5 border border-gray-400`}>
              <h3 css={tw`mb-2`}>STREAMING SPONSOR</h3>
              <LeadText>R15 000</LeadText>
            </td>
            <td css={tw`p-5 border border-gray-400`}>
              <h3 css={tw`mb-2`}>VIRTUAL BREAK</h3>
              <LeadText>R15 000</LeadText>
            </td>
            <td css={tw`p-5 border border-gray-400`}>
              <h3 css={tw`mb-2`}>SNACK VOUCHER</h3>
              <LeadText>IN-KIND</LeadText>
            </td>
          </tr>
        </table>
        <Button
          to="/downloads/events/2020-10-social-justice-summit-sponsorship-prospectus.pdf"
          size="md"
          css={tw`mt-5`}
        >
          Explore sponsorship opportunities &rarr;
        </Button>
      </Section>
      <Section id="watch">
        <h2>Watch now</h2>
        <LeadText>
          Experience the summit through the recordings below. You can{' '}
          <SafeLink
            to="https://www.youtube.com/playlist?list=PLwTL48ihN7WatuOfQIU8rP4B9556H0FLy"
            css={tw`border-b-2 border-maroon hover:text-maroon`}
          >
            browse all sessions
          </SafeLink>{' '}
          here.
        </LeadText>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/videoseries?list=PLwTL48ihN7WatuOfQIU8rP4B9556H0FLy"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Section>
      <Section id="press" isShaded>
        <h2>Press coverage</h2>
        <LeadText>
          The 2020 Summit has generated significant coverage in the South
          African press. Here is a selection of the latest articles:
        </LeadText>
        <ol>
          <li>
            <SafeLink
              to="https://www.dailymaverick.co.za/article/2020-11-05-thuli-madonselas-m-plan-could-it-be-south-africas-answer-to-eradicating-poverty-and-inequality/"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Thuli Madonsela’s ‘M Plan’ – could it be South Africa’s answer to
              eradicating poverty and inequality?
            </SafeLink>
            <br />
            <em>The Daily Maverick</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.news24.com/news24/southafrica/news/we-need-to-speak-honestly-about-incompetent-state-officials-prof-adam-habib-20201105"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              'We need to speak honestly about incompetent state officials' -
              Prof Adam Habib
            </SafeLink>
            <br />
            <em>News24</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.timeslive.co.za/news/south-africa/2020-11-05-the-poor-dont-need-handouts-they-need-empowerment-madonsela/"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              The poor don't need handouts, they need empowerment: Madonsela
            </SafeLink>
            <br />
            <em>TimesLive</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="https://www.polity.org.za/article/law-trust-social-justice-launches-plan-to-eradicate-poverty-2020-11-04"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Law Trust Chair in Social Justice launches plan to eradicate
              poverty
            </SafeLink>
            <br />
            <em>Polity</em>, 04/11/2020
          </li>
          <li>
            <SafeLink
              to="/downloads/posts/summit-2020-press-argus.pdf"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Faith and Social Justice
            </SafeLink>
            <br />
            <em>Cape Argus</em>, 05/11/2020
          </li>
          <li>
            <SafeLink
              to="/downloads/posts/summit-2020-press-sowetan.pdf"
              css={tw`border-b-2 border-maroon hover:text-maroon`}
            >
              Madonsela sets her M-Plan in motion
            </SafeLink>
            <br />
            <em>Sowetan</em>, 05/11/2020
          </li>
        </ol>
      </Section>
    </Layout>
  );
};

export default ProjectsMPlan;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    summit2020Banner: file(relativePath: { eq: "summit-2020-banner.jpg" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    atBoshoff: file(relativePath: { eq: "summit-2020-at-boshoff.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    wimDeVilliers: file(
      relativePath: { eq: "summit-2020-wim-de-villiers.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    adrianEnthoven: file(
      relativePath: { eq: "summit-2020-adrian-enthoven.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ronaldLamola: file(relativePath: { eq: "summit-2020-ronald-lamola.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    thaboMakgoba: file(relativePath: { eq: "summit-2020-thabo-makgoba.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    busisiweMavuso: file(
      relativePath: { eq: "summit-2020-busisiwe-mavuso.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kateRobertson: file(
      relativePath: { eq: "summit-2020-kate-robertson.jpg" }
    ) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    gloriaSerobe: file(relativePath: { eq: "summit-2020-gloria-serobe.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    clareShine: file(relativePath: { eq: "summit-2020-clare-shine.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    nicolaSmit: file(relativePath: { eq: "summit-2020-nicola-smit.jpg" }) {
      childImageSharp {
        fixed(quality: 90, width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
